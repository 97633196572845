$assets-path: '../../assets' !default;

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@mixin themed(
    $property,
    $properties: ('light', 'dark'),
    $dark-mode-selector: '.dark-mode'
) {
    @if (map-get($properties, light)) {
        #{$property}: map-get($properties, light);
    }

    @if (map-get($properties, dark)) {
        @at-root #{$dark-mode-selector} & {
            #{$property}: map-get($properties, dark);
        }
    }
}

@mixin flex(
    $align: stretch,
    $justify: space-between,
    $wrap: nowrap,
    $direction: row,
    $display: flex
) {
    display: $display;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

@function v($var, $fallback: null) {
    @if ($fallback) {
        @return var(--#{$var}, #{$fallback});
    } @else {
        @return var(--#{$var});
    }
}

$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;
$breakpoint-xxl: 1760px;

@mixin large-desktop {
    @media (max-width: calc($breakpoint-xl - 1px)) {
        @content;
    }
}

@mixin xlarge-desktop {
    @media (max-width: calc($breakpoint-xxl - 1px)) {
        @content;
    }
}

@mixin medium-desktop {
    @media (max-width: calc($breakpoint-lg - 1px)) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: calc($breakpoint-md - 1px)) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: calc($breakpoint-sm - 1px)) {
        @content;
    }
}

// TODO - move to the shared library: EONFEH-15903
@mixin app-link($color: v(color-primary)) {
    color: $color;
    font-weight: bold;
    text-decoration: underline;

    @include hover {
        text-decoration: none;
    }
}

@mixin section-title {
    font-weight: 300;
    font-size: 1.625rem;
    margin: 1.25rem 0;
}

@mixin cancel-button {
    color: v(color-white);
    background-color: v(color-grey-button);
}

@mixin cancel-button-hover {
    background-color: v(color-grey-button);
}

@mixin animated-cancel-button {
    @include cancel-button;

    background: linear-gradient(
        to right,
        v(color-grey-hover) 50%,
        v(color-grey-button) 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all ease 1s;

    &:hover {
        @include cancel-button-hover;
        background-position: left bottom;
    }
}

@mixin close-button-position {
    position: absolute;
    top: 0;
    right: 0;
}

@mixin auto-height {
    height: auto !important;
}

.container {
    padding-top: 5.625rem;
}

.wrapper {
    @include flex;
}

.content {
    flex: 1;
    padding: 1rem;
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;

    @include tablet {
        width: 100%;
    }
}

.content,
.wrapper,
.container {
    height: 100%;
}
